import styled from 'styled-components'
import CalendarImg from '../../../imgs/Calendar.svg';

const Wrapper = styled.div`
    width: 287px;
    height: 248px;
    border-radius: 32px;
    gap: 19px;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
    text-align: center;
    //align-items: start;
    //justify-content: center;
    font-family: 'Poppins';
    @media (max-width: 1500px) {
        //width: 150px;
        //height: 150px;
        //border-radius: 25px;
        //gap: 10px;
    }
    @media (max-width: 700px) {
        //width: 130px;
        //height: 130px;
        //border-radius: 13px;
        //gap: 10px;
    }
`

const InnerWrapper = styled.div`
    margin: 30px 20px 20px 25px;
`

const Img1 = styled.img`
    width: 32px;
    height: 32px;
    @media (max-width: 1500px) {
        //width: 40px;
        //height: 45px;
    }
`

const Row1 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
`;

const Title = styled.div`
    margin-left: 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -1%;
    text-align: left;
`;

const Text = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin-top: 15px;
    rgba(51, 64, 75, 1)
    //white-space: pre-wrap;
    @media (max-width: 1500px) {
        //font-size: 17px;
    }
    @media (max-width: 700px) {
        //font-size: 15px;
    }
`

const Footer = styled.div`
    display: flex;
    margin-top: 21px;
`;

const Img2 = styled.img`
    width: 16px;
    height: 16px;
`

const Text2 = styled.div`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: rgba(107, 107, 108, 1);
    margin-left: 6px;

`

function Tile2({icon, title, text, date}) {
    return (
        <Wrapper>
            <InnerWrapper>
                <Row1><Img1 src={icon} /><Title>{title}</Title></Row1>
                <Text>{text}</Text>
                <Footer><Img2 src={CalendarImg}/><Text2>{date}</Text2></Footer>
            </InnerWrapper>
        </Wrapper>
    );
}

export default Tile2;
