import styled from 'styled-components'
import LeaveIcon from "../../imgs/LeaveIcon.svg";
import PerformanceIcon from "../../imgs/PerformanceIcon.svg";
import AttendanceIcon from "../../imgs/AttendanceIcon.svg";
import AssetsIcon from "../../imgs/AssetsIcon.svg";
import HelpDeskIcon from "../../imgs/HelpDeskIcon.svg";
import PeopleIcon from "../../imgs/PeopleIcon.svg";
import FinanceIcon from "../../imgs/FinanceIcon.svg";
import CrmIcon from "../../imgs/CrmIcon.svg";
import OnboardingIcon from "../../imgs/OnboardingIcon.svg";
import DocumentIcon from "../../imgs/DocumentIcon.svg";
import ManagementIcon from "../../imgs/ManagementIcon.svg";
import Tile2 from "../common/tile2/Tile2";

const Wrapper = styled.div`
    text-align: center;
    padding: 70px 50px 100px 50px;
    display: flex;
    flex-direction: row;
    //justify-content: space-between;
    gap: 40px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 700px) {
        gap: 30px;
        padding: 50px 20px 50px 20px;
    }
`

function Section6() {
    const itemData = [
        {
            title: 'People',
            icon: PeopleIcon,
            text: 'Access employee directory, view detailed profile information and career progress.',
            date: 'Launching in December 2024'
        },
        {
            title: 'Leave',
            icon: LeaveIcon,
            text: "Effortlessly manage your team's time off, easy approvals and view team availability with ease.",
            date: 'Launching in December 2024'
        },
        {
            title: 'Attendance',
            icon: AttendanceIcon,
            text: 'Track time, view daily analytics, approve time entries on the go, and see real-time team availability.',
            date: 'Launching in December 2024'
        },
        {
            title: 'Assets',
            icon: AssetsIcon,
            text: 'Track company devices, monitor status changes on repair/maintenance, and facilitate device reallocation.',
            date: 'Launching in March 2025'
        },
        {
            title: 'E Signature',
            icon: DocumentIcon,
            text: 'Digitize your document management and manage files digitally and efficiently in one place.',
            date: 'Launching in March 2025'
        },
        {
            title: 'Finance',
            icon: FinanceIcon,
            text: 'Manage invoices and expenses, handle vendors, and generate financial statements',
            date: 'Launching in June 2025'
        },
        {
            title: 'Project Management',
            icon: ManagementIcon,
            text: 'Plan, track, manage teams & collaborate effortlessly in one platform.',
            date: 'Launching in August 2025'
        },
        {
            title: 'CRM',
            icon: CrmIcon,
            text: 'Boost sales efficiency with real-time insights, effortless communication, and automation.',
            date: 'Launching in October 2025'
        },
        {
            title: 'Help Desk',
            icon: HelpDeskIcon,
            text: 'Raise and manage support tickets, from asset concerns to document requests, ensuring quick resolutions.',
            date: 'Launching in November 2025'
        },
        {
            title: 'Performance',
            icon: PerformanceIcon,
            text: 'Manage performance with goal tracking, feedback, reviews, development and succession planning.',
            date: 'Coming soon'
        },
        {
            title: 'Onboarding / Offboarding',
            icon: OnboardingIcon,
            text: 'Streamline onboarding, off-boarding, orientation, task assignments, and policy management.',
            date: 'Coming soon'
        }
    ]
    return (
        <Wrapper>
            {itemData.map((item, index) => {
                return <Tile2 key={index} title={item.title} icon={item.icon} text={item.text} date={item.date}/>
            })}
        </Wrapper>
    );
}

export default Section6;
