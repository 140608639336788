import styled from 'styled-components'
import ButtonPrimary from "../common/buttonPrimary/ButtonPrimary";
import LogoSkapp from '../../imgs/LogoSkapp.svg';

const Wrapper = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media (max-width: 1000px) {
        justify-content: center;
    }
    @media (max-width: 700px) {
        flex-direction: column;
    }
`

const LogoWrapper = styled.div`
    margin-top: 40px;
    @media (max-width: 1000px) {
        margin-top: -50px;
        margin-bottom: -130px;
    }
    @media (max-width: 700px) {
        margin-top: -100px;
        margin-bottom: -130px;
    }
`;

const Img = styled.img`
    width: 680px;
    height: 290px;
    @media (max-width: 1000px) {
        width: 400px;
    }
    @media (max-width: 700px) {
        width: 200px;
    }
`;

function HeaderNew() {
    return (
        <Wrapper>
            <LogoWrapper><Img src={LogoSkapp}/> </LogoWrapper>
        </Wrapper>
    );
}

export default HeaderNew;
