import styled from 'styled-components'

const Wrapper = styled.div`
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: rgba(136, 136, 136, 1);
    font-family: 'Poppins';
    //@media (max-width: 1000px) {
    //    font-size: 38px;
    //    line-height: 45px;
    //}
    @media (max-width: 700px) {
        font-size: 17px;
        line-height: 25px;
    }
`

function Description({text, ...props}) {
    return (
        <Wrapper {...props}>
            {text}
        </Wrapper>
    );
}

export default Description;
