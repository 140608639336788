import styled from 'styled-components'
import Description from "../common/description/Description";
import Title from "../common/title/Title";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins';
    text-align: center;
    margin: 0 100px 0 100px;
    @media (max-width: 700px) {
        margin: 0 50px 0 50px;
    }
`

const Text1 = styled(Title)`
    margin-top: 70px;
    max-width: 1062px;
`

const Text2 = styled(Title)`
    max-width: 1062px;
`

const Text3 = styled(Description)`
    margin-top: 30px;
    //max-width: 768px;
`

function Section8() {
    return (
        <Wrapper>
            <Text1 text={'We want to help businesses succeed.'}/>
            <Text2 text={'So we have a Free Open Source community version for everyone!'}/>
            <Text3 text={'Checkout our Enterprise options for an advanced, fully-managed and supported platform'}/>
        </Wrapper>
    );
}

export default Section8;
