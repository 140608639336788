import styled from 'styled-components'
import Description from "../common/description/Description";
import Title from "../common/title/Title";
import Card2 from "../common/card2/Card2";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    font-family: 'Poppins';
    text-align: center;
    margin: 30px 80px 0px 80px;
    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0px;
        margin: 30px 50px 50px 50px;
    }
`

function Section9() {
    return (
        <Wrapper>
            <Card2 theme={'light'} title={'Skapp Community'}>Our Community Edition is open and free. You can host it yourself and use it free forever. Follow our <a href={'https://github.com/SkappHQ'}>Github</a> page for our first release in December 2024</Card2>
            <Card2 theme={'dark'} title={'Skapp Enterprise'}>Get access to advanced features designed for scaling businesses. This is fully managed and supported by us. First release in December 2024</Card2>
        </Wrapper>
    );
}

export default Section9;
