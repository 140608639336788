import styled from 'styled-components'
import ButtonPrimary from "../common/buttonPrimary/ButtonPrimary";

const Wrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    padding: 10px 100px 0px 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    @media (max-width: 1000px) {
        padding: 100px 100px 0px 100px;
    }
    @media (max-width: 700px) {
        padding: 50px 30px 0px 30px;
    }
`

const Item1 = styled.div`
    font-weight: 600;
    font-size: 64px;
    line-height: 80px;
    letter-spacing: -4%;
    @media (max-width: 1000px) {
        font-size: 44px;
        line-height: 60px;
    }
    @media (max-width: 700px) {
        font-size: 24px;
        line-height: 30px;
    }
`


const Item2 = styled.div`
    color: #969696;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 30px;
    letter-spacing: -2%;
    @media (max-width: 700px) {
        font-size: 18px;
        line-height: 22px;
        margin-top: 20px;
    }
`

// const Item3 = styled.div`
//    margin-top: 40px;
//     @media (max-width: 700px) {
//         margin-top: 30px;
//     }
// `

const Item3 = styled.div`
    rgba(0, 0, 0, 1)
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 20px;
    letter-spacing: -2%;
    @media (max-width: 700px) {
        font-size: 18px;
        line-height: 22px;
        margin-top: 20px;
    }`

function Section1() {
    return (
        <Wrapper>
            <Item1>Your go-to platform for running your everyday business</Item1>
            <Item2>Make work simpler and productive with Skapp</Item2>
            {/*<Item3><ButtonPrimary/></Item3>*/}
            <Item3>Launching on December 5, 2024</Item3>
        </Wrapper>
    );
}

export default Section1;
