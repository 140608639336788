import styled from 'styled-components'

const Wrapper = styled.div`
    //height: 56px;
    border-radius: 16px;
    padding: 16px 40px;
    background: black;
    color: white;
    display: inline-block;
    font-family: 'Inter';
    @media (max-width: 1000px) {
        padding: 12px 30px;
        border-radius: 10px;
    }
    @media (max-width: 700px) {
        padding: 8px 20px;
        border-radius: 7px;
    }
`

const Text = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -3%;
    @media (max-width: 1000px) {
        font-size: 18px;
    }
    @media (max-width: 700px) {
        font-size: 15px;
    }
`
function ButtonPrimary() {
    return (
        <Wrapper>
            <Text>Explore Now</Text>
        </Wrapper>
    );
}

export default ButtonPrimary;
