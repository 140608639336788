import styled from 'styled-components'

const Wrapper = styled.div`
    font-weight: 600;
    font-size: 48px;
    line-height: 57.6px;
    @media (max-width: 1000px) {
        font-size: 38px;
        line-height: 45px;
    }
    @media (max-width: 700px) {
        font-size: 25px;
        line-height: 35px;
    }
    //font-family: 'Poppins';
`

function Title({text, ...props}) {
    return (
        <Wrapper {...props}>
            {text}
        </Wrapper>
    );
}

export default Title;
