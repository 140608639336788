import styled from 'styled-components'
import Title from "../common/title/Title";
import Description from "../common/description/Description";

const Wrapper = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins';
`

const Text1 = styled(Title)`
    padding-top: 100px;
    @media (max-width: 700px) {
        padding-top: 60px;
    }
`

const Text2 = styled(Description)`
    max-width: 728px;
    margin: 30px 20px 0 20px;
`

const Text3 = styled(Description)`
    margin-top: 80px;
    @media (max-width: 1000px) {
        margin-top: 70px;
    }
    @media (max-width: 700px) {
        margin-top: 50px;
    }
`

const Text4 = styled(Title)`
    margin: 30px 20px 0 20px;
`


function Section3() {
    return (
        <Wrapper>
            {/*<Text1 text={'Our Vision'}/>*/}
            {/*<Text2 text={'“To build an ERP solution that grows with your business, fueled by community-driven innovation and continuous growth”'}/>*/}
            <Text3 text={'An End-to-End Solution'}/>
            <Text4 text={'Built For Your Modern Business'}/>
        </Wrapper>
    );
}

export default Section3;
