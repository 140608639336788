import styled from 'styled-components'
import Description from "../common/description/Description";
import Title from "../common/title/Title";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins';
    text-align: center;
    margin: 150px 30px 0 30px;
`

const Text1 = styled(Title)`
    margin-top: 100px;
    max-width: 768px;
`

const Text2 = styled(Description)`
    margin-top: 30px;
    //max-width: 768px;
`

function Section6() {
    return (
        <Wrapper>
            <Text1 text={'Make your business run efficiently, with the right tools.'}/>
            <Text2 text={'From people management to finance, give your business a powerful tool.'}/>
        </Wrapper>
    );
}

export default Section6;
