import styled from 'styled-components'
import LogoAnimated from '../../imgs/LogoAnimated.gif';
import Description from "../common/description/Description";
import Title from "../common/title/Title";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 100px;
    margin-top: 150px;
    font-family: 'Poppins';
    flex-wrap: wrap;
    @media (max-width: 1300px) {
        display: block;
        text-align: center;
        //margin: 0 auto;
    }
    @media (max-width: 700px) {
        margin-top: 100px;
    }
`

const Left = styled.div`
    @media (max-width: 1300px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 50px 50px 50px;
    }
    @media (max-width: 700px) {
margin-bottom: 30px;
    }
`;

const Right = styled.div`
    
`;

const Text1 = styled(Title)`
    max-width: 520px;
`

const Text2 = styled(Description)`
    max-width: 467px;
    margin-top: 30px;
`

const Img = styled.img`
    width: 463px;
    height: 448px;
    @media (max-width: 1000px) {
        width: 300px;
        height: 300px;
    }
    @media (max-width: 700px) {
        width: 200px;
        height: 200px;
    }
`;

function Section5() {
    return (
        <Wrapper>
            <Left>
                <Text1 text={'Skapp gives you the tools to manage your business workflows better'}/>
                <Text2 text={'The right toolset can make work simple and productive. '}/>
            </Left>
            <Right>
                <Img src={LogoAnimated}/>
            </Right>
        </Wrapper>
    );
}

export default Section5;
