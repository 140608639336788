import styled from 'styled-components'


const Wrapper = styled.div`
    width: 393px;
    min-height: 330px;
    border-radius: 22.35px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background: rgba(255, 255, 255, 1);
    box-shadow: rgba(0, 0, 0, 0.20) 0px 4px 5px;
    @media (max-width: 1000px) {
        width: 353px;
        height:290px;
        min-height: 290px;
    }
    @media (max-width: 700px) {
        width: 303px;
        height:230px;
        min-height: 230px;
    }
`

const Title = styled.div`
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    font-family: 'Poppins';
    max-width: 247px;
    color: rgba(24, 33, 44, 1);
    margin-top: 68px;
    letter-spacing: 1%;
    @media (max-width: 1000px) {
        font-size: 25px;
        line-height: 36px;
        margin-top: 28px;
    }
    @media (max-width: 700px) {
        margin-top: 10px;
        font-size: 22px;
        line-height: 33px;
    }
`

const Description = styled.div`
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    max-width: 345px;
    font-family: 'Poppins';
    margin: 20px 20px;
    letter-spacing: 1%;
    color: rgba(24, 33, 44, 1);
    @media (max-width: 1000px) {
        font-size: 15px;
        line-height: 22px;
        margin: 20px 40px;
    }
    @media (max-width: 700px) {
        margin: 10px 40px;
    }
`

function Card({title, description}) {
    return (
        <Wrapper>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </Wrapper>
    );
}

export default Card;
