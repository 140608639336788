import styled from 'styled-components'
import Card from "../common/card/Card";

const Wrapper = styled.div`
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 70px;
    font-family: 'Poppins';
    flex-wrap: wrap;
    @media (max-width: 1000px) {
       flex-direction: column;
    }
`


function Section4() {
    const cardData = [
        {
            title: 'Easy to use features',
            description: 'With a simple and  intuitive user experience, accessible on web, Android, and iOS.'
        },
        {
            title: 'All-in-one platform',
            description: 'Manage people, time-off, document requesting, assets, finances, time & attendance and more - everything needed to run your modern business.'
        },
        {
            title: 'Built for business growth',
            description: 'Skapp helps you grow your business by streamlining your operations. Our enterprise features are designed to help you scale up and succeed.'
        },
    ]
    return (
        <Wrapper>
            {cardData.map((item, index) => {
                return <Card key={index} title={item.title} description={item.description}/>
            })}
        </Wrapper>
    );
}

export default Section4;
