import styled from 'styled-components'


const Wrapper = styled.div`
    width: 591px;
    height: 330px;
    border-radius: 22.35px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background: white;
    margin-bottom: 100px;
    margin-top: 50px;
    padding-bottom: 70px;
    @media (max-width: 1300px) {
        margin-bottom: 80px;
        margin-top: 50px;
        height: 300px;
    }
    @media (max-width: 1000px) {
        margin-bottom: 30px;
        margin-top: 30px;
        width: auto;
        padding-bottom: 30px;
        height: auto;
    }
`

const Title = styled.div`
    //height: 56px;
    background: ${(props) => (props.theme === 'light' ? `rgba(239, 239, 239, 1)` : '\n' +
            'rgba(0, 0, 0, 1)')};
    color: ${(props) => (props.theme === 'light' ? `black` : '\n' +
            'white')};
    font-family: 'Inter';
    font-weight: 600;
    font-size: 32px;
    line-height: 28px;
    letter-spacing: -3%;
    border-radius: 16px;
    padding: 16px 40px;
    display: flex;
    margin-top: 70px;
    @media (max-width: 1300px) {
        font-size: 25px;
        padding: 13px 36px;
        border-radius: 13px;
    }
    @media (max-width: 700px) {
        background: none;
        color: black;
        padding: 0 20px;
    }
`

const Description = styled.div`
    font-weight: 300;
    font-size: 18px;
    line-height: 32.4px;
    color: rgba(24, 33, 44, 1);
    margin: 50px 20px 20px 20px;
    font-family: 'Poppins';
    max-width: 464px;
    letter-spacing: 1%;
    @media (max-width: 1300px) {
        margin: 30px 60px 20px 60px;
        font-size: 16px;
        line-height: 28px;
    }
    @media (max-width: 700px) {
        margin: 20px 20px 10px 20px;
        line-height: 25px;
    }
`

function Card({title, theme, children}) {
    return (
        <Wrapper>
            <Title theme={theme}>{title}</Title>
            <Description>{children}</Description>
        </Wrapper>
    );
}

export default Card;
