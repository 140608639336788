import styled from 'styled-components'

const Wrapper = styled.div`
    max-width: ${(props) => (props.width ? `${props.width}px` : '1500px')};
    margin: 0 auto;
`

function ContentWrapper({children, width}) {
    return (
        <Wrapper width={width && width}>
            {children}
        </Wrapper>
    );
}

export default ContentWrapper;
