import styled, { keyframes } from 'styled-components'
import Tile from "../common/tile/Tile";
import LeaveIcon from '../../imgs/LeaveIcon.svg'
import PerformanceIcon from '../../imgs/PerformanceIcon.svg'
import AttendanceIcon from '../../imgs/AttendanceIcon.svg'
import AssetsIcon from '../../imgs/AssetsIcon.svg'
import HelpDeskIcon from '../../imgs/HelpDeskIcon.svg'
import PeopleIcon from '../../imgs/PeopleIcon.svg'
import FinanceIcon from "../../imgs/FinanceIcon.svg";
import CrmIcon from "../../imgs/CrmIcon.svg";
import OnboardingIcon from "../../imgs/OnboardingIcon.svg";
import DocumentIcon from "../../imgs/DocumentIcon.svg";
import ManagementIcon from "../../imgs/ManagementIcon.svg";
import AppraisalIcon from "../../imgs/AppraisalIcon.svg";
import {createRef, useEffect, useState} from "react";

const Wrapper = styled.div`
    display: inline-block;
    box-sizing: border-box;
    padding: 50px 0 100px 0;
    overflow-x:hidden;
    width: 100%;
    @media (max-width: 1500px) {
        padding-top: 80px;
        padding-bottom: 70px;
    }
    @media (max-width: 1000px) {
        padding-top: 80px;
    }
    @media (max-width: 700px) {
        padding-top: 50px;
        padding-bottom: 40px;
    }
`

const scrollingAnimation = (distance) => keyframes`
    to {
        transform : translateX(-${distance}px)
    }
`;

const InnerWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    animation : ${props => props.active === 'true' && scrollingAnimation(props.distance)} 25s infinite linear;
`

const TileWrapper = styled.div`
    display: inline-block;
    box-sizing: border-box;
    margin-left: 25px;
    margin-right: 25px;
    @media (max-width: 700px) {
        margin-left: 15px;
        margin-right: 15px;
    }
`

function Section2() {

    const [isAnimationActive, setIsAnimationActive] = useState('false');
    const [animatingDistance, setAnimatingDistance] = useState(0);
    const refInnerWrapper = createRef();

    const itemData = [
        {
            text: 'People',
            icon: PeopleIcon
        },
        {
            text: 'Leave',
            icon: LeaveIcon
        },
        {
            text: 'Attendance',
            icon: AttendanceIcon
        },
        {
            text: 'Assets',
            icon: AssetsIcon
        },
        {
            text: 'E Signature',
            icon: DocumentIcon
        },
        {
            text: 'Finance',
            icon: FinanceIcon
        },
        {
            text: 'Project Management',
            icon: ManagementIcon
        },
        {
            text: 'Performance',
            icon: PerformanceIcon
        },

        {
            text: 'Help Desk',
            icon: HelpDeskIcon
        },

        {
            text: 'People',
            icon: PeopleIcon
        },



        {
            text: 'Onboarding',
            icon: OnboardingIcon
        },


        {
            text: 'Appraisal',
            icon: AppraisalIcon
        },
    ]

    const setAnimationDistance = () => {
        const calculatedAnimatingDistance = refInnerWrapper.current ? refInnerWrapper.current.scrollWidth/2 : 0
        setAnimatingDistance(calculatedAnimatingDistance);
    }

    useEffect(() => {
        setAnimationDistance();
        setIsAnimationActive('true')
    }, [refInnerWrapper]);

    useEffect(() => {
        let prevWidth = window.innerWidth;
        const handleResize = () => {
            let width = window.innerWidth;
            if (width !== prevWidth) {
                prevWidth = width;
                setAnimationDistance()
            }
        }
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return (
        <Wrapper>
            <InnerWrapper ref={refInnerWrapper} active={isAnimationActive} distance={animatingDistance}>
                {itemData.map((item) => {
                    return (
                        <TileWrapper key={'1'+item.text}>
                            <Tile text={item.text} icon={item.icon}/>
                        </TileWrapper>
                    )
                })}
                {itemData.map((item) => {
                    return (
                        <TileWrapper key={'2'+item.text}>
                            <Tile text={item.text} icon={item.icon}/>
                        </TileWrapper>
                    )
                })}
            </InnerWrapper>
        </Wrapper>
    );
}

export default Section2;
