import styled from 'styled-components'


const Wrapper = styled.div`
    width: 196px;
    height: 196px;
    border-radius: 32px;
    //padding: 54px 69px 46px 69px;
    gap: 19px;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins';
    @media (max-width: 1500px) {
        width: 150px;
        height: 150px;
        border-radius: 25px;
        gap: 10px;
    }
    @media (max-width: 700px) {
        width: 130px;
        height: 130px;
        border-radius: 13px;
        gap: 10px;
    }
`

const Img = styled.img`
    width: 45px;
    height: 50px;
    @media (max-width: 1500px) {
        width: 40px;
        height: 45px;
    }
`

const Text = styled.div`
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    //white-space: pre-wrap;
    @media (max-width: 1500px) {
        font-size: 17px;
    }
    @media (max-width: 700px) {
        font-size: 15px;
    }
`

function Tile({text, icon}) {
    return (
        <Wrapper>
            <Img src={icon} />
            <Text>{text}</Text>
        </Wrapper>
    );
}

export default Tile;
