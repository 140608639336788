import './App.css';
import Section1 from "./components/section1/Section1";
import ContentWrapper from "./components/common/ContentWrapper/ContentWrapper";
import Section2 from "./components/section2/Section2";
import styled from "styled-components";
import Section3 from "./components/section3/Section3";
import Section4 from "./components/section4/Section4";
import Section5 from "./components/section5/Section5";
import Section6 from "./components/section6/Section6";
import Section7 from "./components/section7/Section7";
import Section8 from "./components/section8/Section8";
import Section9 from "./components/section9/Section9";
import Footer from "./components/footer/Footer";
import HeaderNew from "./components/headerNew/HeaderNew";

const PagePart1 = styled.div`
    background: #EFEFEF;
`

const PagePart2 = styled.div`
    background: white;
`

function App() {
  return (
    <div className="App">
        <PagePart1>
            <HeaderNew/>
            <Section1/>
            <Section2/>
        </PagePart1>
        <PagePart2>
            <Section3/>
            <ContentWrapper>
                <Section4/>
            </ContentWrapper>
            <ContentWrapper>
                <Section5/>
            </ContentWrapper>
        </PagePart2>
        <PagePart1>
            <ContentWrapper>
                <Section6/>
            </ContentWrapper>
            <ContentWrapper width={1800}>
                <Section7/>
            </ContentWrapper>
            <ContentWrapper>
                <Section8/>
            </ContentWrapper>
            <ContentWrapper>
                <Section9/>
            </ContentWrapper>
            <Footer/>
        </PagePart1>
    </div>
  );
}

export default App;
