import styled from 'styled-components'
import ContentWrapper from "../common/ContentWrapper/ContentWrapper";
import FbIcon from '../../imgs/fbIcon.svg';
import LinkedInIcon from '../../imgs/linkedinIcon.svg';
import InstaIcon from '../../imgs/instaIcon.svg';
import LogoRootcode from '../../imgs/LogoRootcode.svg';

const Wrapper = styled.div`
    background: rgba(0, 0, 0, 1);
    font-family: 'Poppins';
    padding: 150px 50px 150px 50px;
`

const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const Row1 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 1000px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const Row1Left = styled.div`
    font-weight: 700;
    font-size: 36px;
    line-height: 46.8px;
    color: rgba(255, 255, 255, 1);
    letter-spacing: 1%;
    @media (max-width: 1200px) {
        text-align: center;
    }
`;

const Row1Right = styled.div`
    @media (max-width: 1000px) {
        margin-top: 30px;
        margin-bottom: 30px;
    }`;

const Line = styled.div`
    width: 100%;
    display: block;
    height: 0.5px;
    background: white;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Row2 = styled.div`
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    rgba(255, 255, 255, 1);
    @media (max-width: 1200px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const Img = styled.img`
    height: 30px;
    width: 30px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const RowRightWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
`

const Row2Left = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
`

const Row2Middle = styled.div`
    @media (max-width: 1200px) {
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
    }`

const Row2Right = styled.div``

const ImgLogo = styled.img`
    width: 116px;
    height: 38.67px;
    margin-left: 20px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;   
    }
`;

function Footer() {

    const logoClick = () => {
        window.location.href = 'https://rootcode.io/'
    }

    const fbClick = () => {
        window.location.href = 'https://web.facebook.com/skapphq'
    }

    const linkedInClick = () => {
        window.location.href = 'https://www.linkedin.com/company/skapp-io/'
    }

    const instagramClick = () => {
        window.location.href = 'https://www.instagram.com/skapphq/'
    }

    return (
        <Wrapper>
            <ContentWrapper>
                <InnerWrapper>
                    <Row1>
                       <Row1Left>Follow our journey</Row1Left>
                       <Row1Right>
                           <RowRightWrapper>
                               <Img src={FbIcon} onClick={fbClick}/>
                               <Img src={LinkedInIcon} onClick={linkedInClick}/>
                               <Img src={InstaIcon} onClick={instagramClick}/>
                           </RowRightWrapper>
                       </Row1Right>
                    </Row1>
                    <Line/>
                    <Row2>
                        <Row2Left>A Product by <ImgLogo src={LogoRootcode} onClick={logoClick}/> </Row2Left>
                        {/*<Row2Middle>Copyright © 2024 MyLeave. All rights reserved.</Row2Middle>*/}
                        <Row2Right>Copyright © 2024 Rootcode. All rights reserved.</Row2Right>
                    </Row2>
                </InnerWrapper>
            </ContentWrapper>
        </Wrapper>
    );
}

export default Footer;
